import SpaceService from '~/services/SpaceService'

export function useUrlHelpers() {
  const { normalizeStringForUrl } = useStringHelpers()

  const getSpaceUrl = (space: any): string => {
    const city = normalizeStringForUrl(space.address.city)
    const neighborhood = normalizeStringForUrl(space.address.neighborhood)
    const spaceName = normalizeStringForUrl(space.name)
    return `/espacos/${city}/${neighborhood}/${spaceName}--${space.id}`
  }

  const getSpaceUrlBySpaceId = async (spaceId: string): Promise<string> => {
    const space = await SpaceService.getSpaceById(spaceId)
    return getSpaceUrl(space)
  }

  return {
    getSpaceUrl,
    getSpaceUrlBySpaceId,
  }
}
